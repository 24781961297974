.App {
  font-family: Arial, sans-serif;
  text-align: center;
  padding-top: 20px;
  background-image: linear-gradient(to right, #1e1e1e, #020e32, #262740);
  min-height: 100vh;
}

.nav-body{
  background-image: linear-gradient(to right, #242424, #02091f, #1a1b2c);
}

.nav-links a{
  text-decoration: none;
}

.profile-picture img{
  margin-bottom: 0px !important;
}

.movie-detail-body {
  font-family: Arial, sans-serif;
  padding: 40px;
  background-image: linear-gradient(to right, #1e1e1e, #020e32, #262740);
  min-height: 100vh;
  margin-top: 0px !important;
}

.trailer-iframe{
  border-radius: 10px;
  border: solid white 1px;
}

.watch-providers-container h2{
  color: white;
}

.watch-providers-container h4{
  color: white;
}

.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  padding: 15px;
  border-radius: 25px;
  width: 30%;
  margin: 62px auto 62px auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.search-bar input {
  flex: 1;
  border: none;
  background-color: transparent;
  padding: 10px;
  outline: none;
  font-size: 16px;
  border-radius: 20px 0 0 20px;
}

.search-bar button {
  padding: 10px 20px;
  border: none;
  background-color: #47dbfb;
  color: black;
  font-weight: bold;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-bar button:hover {
  background-color: #187bcd;
}

/* Modern Styles for the Movies List */
.movies-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Provides consistent spacing between items */
  padding: 20px;
}

.movie {
  text-decoration: none;
  position: relative;
  width: 200px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 0 16px rgba(255, 255, 255, 0.5);
  margin: 15px;
  border-radius: 15px;
}

.mobile-nav-links {
  text-decoration: none;
  color: black;
}

.movie:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.movie img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.movie div {
  bottom: 0;
  width: 100%;
  padding: 10px 0px 10px 0px;
  color: white;
  font-weight: 500;
  border-radius: 0 0 10px 10px;
  height: auto;
}

img {
  height: 300px;
  margin-bottom: 10px;
  /* border-color: rgb(255 255 255 / 60%);
  border-style: solid; */
}

/* src/components/MovieDetail.css */
.movie-detail {
  position: relative;
  height: auto;
  color: black;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, #1e1e1e, #020e32, #262740);
}

.movie-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.movie-content {
  position: relative;
  z-index: 2;
  display: flex;
  gap: 20px;
  width: 90%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  padding-top: 4%;
  padding-bottom: 4%;
}

.movie-poster {
  width: 100%;
  height: auto !important;
  object-fit: cover;
  flex-shrink: 0;
  box-shadow: 0 0 16px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

.movie-content img{
  border-radius: 20px;
}

.movie-stream-inside {
  background: rgb(255 255 255 / 60%);
  height: auto;
  border-radius: 20px;
  padding-left:20px;
  padding-right: 20px;
}

.movie-stream {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 40%;
}

.movie-text {
  min-width: 50%;
  padding: 0px 20px 20px 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 20px;
  color: white;
}

.movie-text h1 {
  margin: 0;
  font-size: 2.5rem;
  font-weight: bold;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
}

.movie-text p {
  flex-grow: 1;
  opacity: 0.8;
  font-size: 18px;
}

body {
  margin: 0;
  padding: 0;
}

/* Style the navigation bar */
.navbar {
  /* background-image: linear-gradient(to right, #262740, #020e32, #1e1e1e); */
  background-color: #191919; /* Background color for the navigation bar */
  color: #fff; /* Text color */
  padding: 10px 0; /* Vertical padding */
}

.container {
  display: flex;
  justify-content: space-between; /* Align the logo and nav links at opposite ends */
  align-items: center;
  max-width: 1200px; /* Max width of the container */
  margin: 0 auto; /* Center the container horizontally */
  padding: 0 20px;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
  height: 30px;
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-item {
  margin-right: 20px; /* Space between navigation items */
}

.nav-item:last-child {
  margin-right: 0; /* Remove margin from the last item */
}

.nav-item a {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s; /* Smooth color transition on hover */
}

.nav-item a:hover {
  color: #00bcd4; /* Color change on hover */
}

.modern-select {
  height: 50px;
  width: 100%;
  border-radius: 10px;
  padding: 14px;
  font-size: 15px;
  background-color: #d0d0d0;
}

.modern-select:focus {
  outline: none;
}

.select-box {
  margin-bottom: 20px;
}

.watch-provider-list {
    display: flex;
    flex-wrap: wrap;
}

.watch-provider {
  text-decoration: none;
    position: relative;
    width: 20%;
}

.watch-provider img{
  height: 50% !important;
  border: solid;
  border-width: 2px;
  border-color: #ada6a6;
  box-shadow: 0px 11px 17px rgb(0 0 0 / 17%);
  transition: transform 0.3s ease;
}

.watch-provider img:hover {
  transform: scale(1.1);
}

.subtitle-list-element {
  background-color: #3c6586;
  color: white;
  border-radius: 10px;
  text-decoration: none;
  padding:10px;
  border-style: none;
}

.subtitle-list {
  line-height: 50px;
}

.subtitle-list li{
  list-style: none;
}

.subtitle-login {
  font-weight: 500;
  height: 60px;
  font-size: 18px;
  background-color: #47dbfb;
  border-radius: 10px;
  border-style: none;
}

.movie-download-button {
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
  color: white;
  border-radius: 10px;
  width: 100%;
  margin:10px 10px 10px 0px;
  padding: 20px;
  font-size: 16px;
  border-style: none;
  cursor: pointer;
  background: unset;
}

.auth-button {
  background-color: #47dbfb;
  color: black;
  border-style: solid;
  border-width: 2px;
  border-color: #47dbfb;
  border-radius: 5px;
}

.subtitles-container {
  height: 300px;
  overflow: scroll;
}

.movie-details-container {
  background-color: rgb(255 255 255 / 60%);
  height: 100px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 20px;
}

.logo-img {
  height: 35px;
  margin-bottom: 0px !important;
}

.load-more-button {
  height: 60px;
  width: 20%;
  border-radius: 10px;
  border-style: none;
  background-image: linear-gradient(45deg, #0bdaff, #fffcfc);
  font-weight: 900;
  font-size: 15px;
  margin-bottom: 40px;
}

.home-title {
  color: white;
  font-weight: 900 !important;
  margin-top: 30px !important;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
}

.home-subtitle {
  color: white;
}

.movies-type-text{
  color: white;
  text-align: left;
  padding-left: 75px;
  font-size: 41px;
}

.movies-no-results-text{
  font-size: 20px;
  color: #47dbfb;
  text-align: left;
  padding-left: 75px;
}

.movie-count{
  color: yellow;
  font-weight: 900;
}

.download-button-on-list{
  border-radius: 0px !important;
  margin-top: -25px !important;
  color: #020e32 !important;
  box-shadow: 0 0 7px rgba(255, 255, 255, 0.5);
  background-color: #47dbfb !important;
  width: 100%;
}

.movie-count-text{
  text-align: left;
  color: #8a8a8a;
  font-size: 20px;
  padding-left: 75px;
  margin-top: -25px;
}
